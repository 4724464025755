import { mapActions, mapState } from "vuex";

import {
  GET_CATEGORIES,
  GET_COMPANY_SUPPLIERS,
  GET_COMPANY_ADDRESSES,
  GET_DEPARTMENTS,
  GET_USERS,
  GET_PAYMENT_METHODS,
  COMPANY_MANAGEMENT_MODULE,
  GET_LEGAL_TENDENCIES,
  GET_INTEGRATIONS,
  GET_CUSTOM_FIELDS,
  GET_COMPANY_INTEGRATED_SUPPLIERS,
  GET_INTEGRATIONS_SLUGS,
  GET_GLOBAL_POLICIES,
  GET_TAX_CODES,
} from "@/store/CompanyManagement/types";
import { PROJECT_MODULE, GET_PROJECTS } from "@/store/Project/types";
import { ALLOWED_API_ON_ROUTE } from "@/utils/constants.js";
import { CATALOGUE_MODULE, GET_CATALOGUES } from "@/store/Catalogue/types";
import {
  getItemFromLocalStorage,
  isObjectEmpty,
} from "@/utils/utility_methods";
import { AUTH_MODULE } from "@/store/Auth/types";
import { FEATURE_MODULE } from "@/store/Feature/types";
import store from "@/store";

export default {
  computed: {
    ...mapState(AUTH_MODULE, ["user"]),
    ...mapState(CATALOGUE_MODULE, ["catalogues"]),
    ...mapState(PROJECT_MODULE, ["projects"]),
    ...mapState(COMPANY_MANAGEMENT_MODULE, [
      "companySuppliers",
      "companyAddresses",
      "departments",
      "paymentMethods",
      "users",
      "categories",
      "legalTendencies",
      "integrations",
      "customFields",
      "integrationsSlugs",
      "companyIntegratedSuppliers",
      "globalPolicies",
      "taxCodes",
    ]),
    isUserSelfSignUpInfoCompleted() {
      const user = !isObjectEmpty(this.user)
        ? this.user
        : getItemFromLocalStorage("user");
      return !isObjectEmpty(user) && !user?.selfSignUp;
    },
  },
  created() {},

  watch: {
    $route(to) {
      !isObjectEmpty(this.user) && this.fetchApis(to);
    },
    user: {
      handler(newVal) {
        if (newVal?.featureFlags?.length) {
          this.fetchApis(this.$route);
        }
      },
    },
  },
  methods: {
    ...mapActions(COMPANY_MANAGEMENT_MODULE, {
      getCategories: GET_CATEGORIES,
      getCompanySuppliers: GET_COMPANY_SUPPLIERS,
      getIntegratedSuppliers: GET_COMPANY_INTEGRATED_SUPPLIERS,
      getCompanyAddresses: GET_COMPANY_ADDRESSES,
      getDepartments: GET_DEPARTMENTS,
      getPaymentMethods: GET_PAYMENT_METHODS,
      getUsers: GET_USERS,
      getLegalTendencies: GET_LEGAL_TENDENCIES,
      getIntegrations: GET_INTEGRATIONS,
      getCustomFields: GET_CUSTOM_FIELDS,
      getIntegrationsSlugs: GET_INTEGRATIONS_SLUGS,
      getGlobalPolicies: GET_GLOBAL_POLICIES,
      getTaxCodes: GET_TAX_CODES,
    }),
    ...mapActions(PROJECT_MODULE, {
      getProjects: GET_PROJECTS,
    }),
    ...mapActions(CATALOGUE_MODULE, {
      getCatalogues: GET_CATALOGUES,
    }),
    fetchApis(route) {
      if (
        getItemFromLocalStorage("routeToRedirect") &&
        route?.name === "Dashboard"
      )
        return;
      if (!route) return;
      const apiArray = [];
      const routeApis = ALLOWED_API_ON_ROUTE(route.name);

      if (!routeApis) return;
      routeApis.forEach((api) => {
        if (!Object.keys(this[api]).length) {
          const { action, params, allowed } = this.getApiMethod(api);
          if (allowed) {
            apiArray.push(action(params));
            this.setApiLoading({ key: api, value: true });
          }
        }
      });
      if (apiArray.length) {
        Promise.all(apiArray)
          .catch((error) => this.showErrorMessage(error))
          .finally(() => {
            routeApis.forEach((api) => {
              this.setApiLoading({ key: api, value: false });
            });
          });
      }
    },
    getApiMethod(apiName) {
      return {
        users: {
          action: this.getUsers,
          params: {},
          allowed: this.isUserSelfSignUpInfoCompleted,
        },
        projects: {
          action: this.getProjects,
          params: {},
          allowed:
            this.isAllowed("projects") && this.isUserSelfSignUpInfoCompleted,
        },
        categories: {
          action: this.getCategories,
          params: {},
          allowed: this.isUserSelfSignUpInfoCompleted,
        },
        companySuppliers: {
          action: this.getCompanySuppliers,
          params: {},
          allowed: this.isUserSelfSignUpInfoCompleted,
        },
        companyIntegratedSuppliers: {
          action: this.getIntegratedSuppliers,
          params: {},
          allowed: this.isUserSelfSignUpInfoCompleted,
        },
        companyAddresses: {
          action: this.getCompanyAddresses,
          params: {},
          allowed: this.isUserSelfSignUpInfoCompleted,
        },
        departments: {
          action: this.getDepartments,
          params: {},
          allowed: this.isUserSelfSignUpInfoCompleted,
        },
        paymentMethods: {
          action: this.getPaymentMethods,
          params: {},
          allowed: true,
        },
        catalogues: {
          action: this.getCatalogues,
          params: {},
          allowed: this.isUserSelfSignUpInfoCompleted,
        },
        legalTendencies: {
          action: this.getLegalTendencies,
          params: {},
          allowed: this.isAllowed("legalEntity"),
        },
        integrations: {
          action: this.getIntegrations,
          params: {},
          allowed: this.isUserSelfSignUpInfoCompleted,
        },
        integrationsSlugs: {
          action: this.getIntegrationsSlugs,
          params: {},
          allowed: true,
        },
        customFields: {
          action: this.getCustomFields,
          params: {},
          allowed: this.isAllowed("customFields"),
        },
        globalPolicies: {
          action: this.getGlobalPolicies,
          params: {},
          allowed: true,
        },
        taxCodes: {
          action: this.getTaxCodes,
          params: {},
          allowed: true,
        },
      }[apiName];
    },
    isAllowed(apiKey) {
      const featureFlagGetter =
        store.getters[`${FEATURE_MODULE}/featureFlagValue`];
      return featureFlagGetter(apiKey);
    },
  },
};
