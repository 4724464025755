<template>
  <div v-if="apiLoading" class="flex items-center align-middle h-96">
    <HivebuyLoader class="w-20 h-20" />
  </div>
  <div v-else class="mx-auto lg:max-w-[90rem] xl:max-w-[120rem] relative">
    <div class="grid grid-cols-12 gap-4">
      <div
        id="left-grid"
        class="col-span-full md:col-span-9 flex flex-col gap-y-4"
      >
        <div class="flex pl-1 justify-between">
          <span
            class="font-semibold text-xl text-gray-800 decoration-dashed underline underline-offset-4"
          >
            <Inplace :closable="true">
              <template #display>
                {{ requestTitle }}
              </template>
              <template #content>
                <HText v-model="requestTitle" />
              </template>
              <template #closeicon>
                <CheckIcon class="h-4 w-4" />
              </template>
            </Inplace>
          </span>
        </div>
        <div
          v-if="!isFeatureAllowed('itemLevelApproval')"
          class="pt-4 -mt-4 pb-4 bg-gray-100 sm:sticky sm:-top-8 z-20"
        >
          <RequestHeader
            v-model="itemZero"
            :v="validationObject"
            class="bg-white rounded-xl border border-gray-300 mt-4"
            @default-values-change="handleDefaultValues"
          />
        </div>

        <div class="rounded space-y-4">
          <div v-for="(_item, index) in items" :key="index" class="w-full">
            <ItemForm
              :ref="`itemForm${index}`"
              :index="index"
              :set-validation-object="setValidationObject"
            />
          </div>
          <div
            v-if="!isIntegratedRequest"
            v-auto-animate
            class="flex flex-col w-full rounded-lg border-2 border-dashed border-gray-200 py-4 px-2 text-center hover:border-gray-300 text-gray-400 hover:bg-white bg-gray-50 hover:text-primary cursor-pointer"
          >
            <span @click="() => addItem()">
              <PlusIcon class="mx-auto h-8 w-8" />
              <span class="mt-2 block text-base font-medium">
                {{ $t("purchaseRequest.summary.addProductButton") }}
              </span>
            </span>
          </div>
        </div>
      </div>
      <div
        id="right-grid"
        class="col-span-full md:col-span-3 flex flex-col-reverse md:flex-col gap-y-2"
      >
        <SaveButtons :allow-create="false" :get-refs="getRefs" />
        <div class="sticky top-[100px]">
          <Price />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ItemForm, Price, RequestHeader } from "@/components/RequestCreation";
import { PlusIcon, CheckIcon } from "@heroicons/vue/solid";
import { HivebuyLoader } from "@/components/shared/index";
import { newRequestMixin } from "@/components/mixins/index.js";
import { mapActions } from "vuex";
import {
  PURCHASE_REQUEST_MODULE,
  GET_DRAFT_REQUEST,
} from "@/store/PurchaseRequest/types";
import currencyLocaleMixin from "@/components/mixins/currencyLocaleMixin.js";
import SaveButtons from "@/components/RequestCreation/SaveButtons.vue";
import Inplace from "primevue/inplace";
import { HText } from "design-buy";
export default {
  name: "RequestForm",
  components: {
    RequestHeader,
    Price,
    ItemForm,
    CheckIcon,
    PlusIcon,
    HivebuyLoader,
    SaveButtons,
    Inplace,
    HText,
  },
  mixins: [newRequestMixin, currencyLocaleMixin],

  props: {
    modelValue: {
      type: Object,
      default: () => {},
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      validationObject: {},
      showAddOptions: false,
    };
  },

  computed: {
    isIntegratedRequest() {
      return this.items.some((item) => item.isIntegratedSupplierItem);
    },
    requestTitle: {
      get() {
        return this.newRequest.title || "New Request";
      },
      set(value) {
        this.saveRequestAttributes({ title: value });
      },
    },
    itemZero: {
      get() {
        const item = this.items.find((item) => item.isCatalogueItem);
        return item || this.items[0] || {};
      },
      set(value) {
        const {
          requestedForId,
          requestedForType,
          category,
          companySupplier,
          department,
          project,
          unlistedSupplier,
          unlistedSupplierReason,
          skipSupplier,
        } = value;
        this.saveAttributes(
          {
            requestedForId,
            requestedForType,
            department,
            project,
            category,
            companySupplier,
            unlistedSupplier,
            unlistedSupplierReason,
            skipSupplier,
          },
          true
        );
      },
    },
    apiLoading() {
      const keys = [
        "members",
        "departments",
        "categories",
        "projects",
        "companySuppliers",
        "companyAddresses",
        "paymentMethods",
        "customFields",
      ];
      return keys.some((key) => this.isApiLoading(key));
    },
  },
  created() {
    if (!this.items.length) this.addItem();
    if (this.$route.params.id) this.getDraftRequestDetails();
  },
  methods: {
    ...mapActions(PURCHASE_REQUEST_MODULE, {
      getRequestDetails: GET_DRAFT_REQUEST,
    }),
    setValidationObject(val) {
      this.validationObject = val;
    },
    handleDefaultValues(values) {
      const {
        paymentMethod,
        invoiceAddress,
        deliveryAddress: companyAddress,
      } = values;

      this.saveAttributes({
        paymentMethod,
        invoiceAddress,
        companyAddress,
      });
    },
    valueObject(value, isCatalogueItem, skipCatalogue = false) {
      const catalogueItemSkipFields = [
        "category",
        "companySupplier",
        "unlistedSupplier",
        "unlistedSupplierReason",
      ];
      const obj = Object.assign({}, value);
      if (!isCatalogueItem || !skipCatalogue) return obj;
      catalogueItemSkipFields.forEach((field) => {
        delete obj[field];
      });
      return obj;
    },
    saveAttributes(val, skipCatalogue = false) {
      this.items.forEach((item, index) => {
        this.saveRequestAttributes({
          item: Object.assign(
            {},
            item,
            this.valueObject(val, item.isCatalogueItem, skipCatalogue)
          ),
          index,
        });
      });
    },
    getRefs() {
      return this.$refs;
    },
    getDraftRequestDetails() {
      this.setApiLoading({ key: "draftFetch", value: true });
      this.getRequestDetails(this.$route.params.id)
        .catch((error) => {
          this.showErrorMessage(error);
        })
        .finally(() => {
          this.setApiLoading({ key: "draftFetch", value: false });
        });
    },
    addCatalogueItem() {
      this.$router.push({ name: "Catalogue" });
      this.showAddOptions = false;
    },
    addItem() {
      const obj = {
        name: "",
        quantity: 1,
        unit: "pc",
        recurrence: { interval: "" },
        netAmount: 0,
        grossAmount: 0,
        tax: 0,
        vat: 19,
        grossAmountCurrency: this.company.currency,
        netAmountCurrency: this.company.currency,
        taxCurrency: this.company.currency,
      };
      if (this.items.length) {
        const refItem = this.items[0];
        obj.category = refItem.category;
        obj.companySupplier = refItem.companySupplier;
        obj.unlistedSupplier = refItem.unlistedSupplier;
        obj.unlistedSupplierReason = refItem.unlistedSupplierReason;

        obj.companyAddress = refItem.companyAddress;
        obj.invoiceAddress = refItem.invoiceAddress;
        obj.paymentMethod = refItem.paymentMethod;
        obj.requestedForId = refItem.requestedForId;
        obj.department = refItem.department;
        obj.project = refItem.project;
        obj.requestedForType = refItem.requestedForType;
      }
      this.saveRequestAttributes({
        item: obj,
      });
      this.showAddOptions = false;
    },
  },
};
</script>

<style>
.parent {
  @apply flex gap-x-4;
}

.price {
  @apply lg:mt-0 w-3/12 max-h-[70vh] top-[7.5rem];
  position: sticky;
}

#new-request-filters {
  @apply bg-white rounded p-2 mb-4 border border-gray-200 shadow-sm flex gap-x-4;
}

.p-overlaypanel {
  overflow: scroll;
  padding: 0rem;
}

.p-overlaypanel .p-overlaypanel-content {
  padding: 0rem !important;
}

.panel {
  @apply md:max-w-[80vw] lg:max-w-[65vw] md:max-h-[80vh] max-h-full lg:max-h-[65vh] overflow-scroll;
}

.p-inputtext:focus {
  @apply !ring-0 shadow-none border-gray-300;
}

.header {
  @apply pt-4 px-4 text-base font-semibold;
}

.p-inplace-display {
  @apply font-semibold text-xl text-gray-800 border border-transparent;
}

.p-inplace .p-inplace-display:not(.p-disabled):hover {
  @apply !bg-gray-50 border border-gray-300;
}

.p-inplace .p-inplace-display:focus {
  @apply !shadow-none;
}

.p-inplace-content {
  @apply flex items-center gap-x-2;
}
</style>
