<template>
  <Modal
    :close-modal="onModalClose"
    :show-icon="false"
    :show-modal="showModal"
    :size-classes="['sm:max-w-3xl md:max-w-4xl']"
    :scrollable-content="false"
  >
    <template #header>
      <h3 class="text-lg font-semibold text-primary">
        {{ $t("purchaseRequest.summary.accountingAdjustments.buttonText") }}
      </h3>
    </template>
    <template #body>
      <div v-if="loading" class="w-full flex items-center justify-center">
        <div class="w-10 h-10"><HivebuyLoader /></div>
      </div>
      <div v-else>
        <div class="mb-2 text-base">
          {{ $t("purchaseRequest.summary.accountingAdjustments.header") }}
          <p class="text-sm font-semibold">
            {{ $t("purchaseRequest.summary.accountingAdjustments.subtext") }}
          </p>
        </div>
        <div class="relative py-4">
          <div class="absolute inset-0 flex items-center" aria-hidden="true">
            <div class="w-full border-t border-gray-300" />
          </div>
          <div class="relative flex justify-center">
            <span class="bg-white px-2 text-sm text-gray-500">{{
              $t("purchaseRequest.summary.accountingAdjustments.adjustValues")
            }}</span>
          </div>
        </div>
        <div class="grid grid-cols-1 gap-x-4 lg:gap-x-6 gap-y-8 sm:grid-cols-3">
          <div class="sm:col-span-1">
            <dt v-focus class="text-sm font-medium text-gray-500">
              {{
                $t(
                  "purchaseRequest.summary.accountingAdjustments.currentCategory"
                )
              }}
            </dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ categoryDetails(currentValues.category) }} /
              {{
                categoryDetails(currentValues.category, true)?.glAccount || "-"
              }}
            </dd>
          </div>
          <div class="sm:col-span-1">
            <dd class="mt-1 text-sm text-gray-900">
              <CategorySelect
                v-model="category"
                :options="filteredCategories"
              />
            </dd>
          </div>
          <div class="sm:col-span-1">
            <dt v-if="category" class="text-sm font-medium text-hivebuy-green">
              Update to:
            </dt>
            <dd v-if="category" class="mt-1 text-sm text-gray-900">
              {{ categoryDetails(category) }} /
              {{ categoryDetails(category, true).glAccount || "-" }}
            </dd>
          </div>
        </div>
        <div
          class="grid grid-cols-1 gap-x-4 lg:gap-x-6 gap-y-8 sm:grid-cols-3 mt-8"
        >
          <div class="sm:col-span-1">
            <dt v-focus class="text-sm font-medium text-gray-500">
              {{
                $t(
                  "purchaseRequest.summary.accountingAdjustments.currentDepartment"
                )
              }}
            </dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ departments[currentValues.department]?.name }} /
              {{ departments[currentValues.department]?.costCenter || "-" }}
            </dd>
          </div>
          <div class="sm:col-span-1">
            <dd class="mt-1 text-sm text-gray-900">
              <DepartmentSelect
                v-model="department"
                :label="'name'"
                :track-by="'name'"
                :can-clear="false"
                open-direction="top"
              />
            </dd>
          </div>
          <div class="sm:col-span-1">
            <dt
              v-if="department"
              class="text-sm font-medium text-hivebuy-green"
            >
              Update to:
            </dt>
            <dd v-if="department" class="mt-1 text-sm text-gray-900">
              {{ departments[department].name }} /
              {{ departments[department].costCenter }}
            </dd>
          </div>
        </div>
        <div
          v-if="companySetting('SPECIAL_PROJECT_PROCESS')?.active"
          class="grid grid-cols-1 gap-x-4 lg:gap-x-6 gap-y-8 sm:grid-cols-3 mt-8"
        >
          <div class="sm:col-span-1">
            <dt v-focus class="text-sm font-medium text-gray-500">
              {{
                $t(
                  "purchaseRequest.summary.accountingAdjustments.currentProject"
                )
              }}
            </dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ getProject(currentValues.project)?.name || "-" }} /
              {{ getProject(currentValues.project)?.costCenter || "-" }}
            </dd>
          </div>
          <div class="sm:col-span-1">
            <dd class="mt-1 text-sm text-gray-900">
              <ProjectSelect
                v-model="project"
                :can-clear="false"
                :placeholder="
                  $t(
                    'purchaseRequest.summary.accountingAdjustments.changeValue'
                  )
                "
              />
            </dd>
          </div>
          <div class="sm:col-span-1">
            <dt v-if="project" class="text-sm font-medium text-hivebuy-green">
              Update to:
            </dt>
            <dd v-if="project" class="mt-1 text-sm text-gray-900">
              {{ getProject(project).name }} /
              {{ getProject(project).costCenter }}
            </dd>
          </div>
        </div>
      </div>
    </template>
    <template v-if="!loading" #footer>
      <Button
        v-focus
        :color="'gray'"
        :loading="isApiLoading('prEdit')"
        :on-click-handler="onModalClose"
      >
        {{ $t("buttons.cancel") }}
      </Button>
      <Button
        v-focus
        :on-click-handler="save"
        :loading="isApiLoading('prEdit')"
      >
        {{ $t("buttons.save") }}
      </Button>
    </template>
  </Modal>
</template>

<script>
import {
  Modal,
  Button,
  CategorySelect,
  DepartmentSelect,
  HivebuyLoader,
} from "@/components/shared/index";
import { mapGetters, mapState, mapActions } from "vuex";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
import {
  EDIT_PURCHASE_REQUEST,
  PURCHASE_REQUEST_MODULE,
} from "@/store/PurchaseRequest/types";
import { GET_PROJECT, PROJECT_MODULE } from "@/store/Project/types";
import ProjectSelect from "@/components/shared/ProjectSelect.vue";

export default {
  components: {
    ProjectSelect,
    DepartmentSelect,
    Modal,
    Button,
    CategorySelect,
    HivebuyLoader,
  },
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },
    onSave: {
      type: Function,
      default: () => {},
    },
    currentValues: {
      type: Object,
      required: true,
    },
    product: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      category: null,
      department: null,
      project: "",
      projectDetails: {},
    };
  },
  computed: {
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, [
      "categoryDetails",
      "categoriesList",
      "companySetting",
      "categoryTreeWithChildren",
    ]),
    ...mapGetters(PROJECT_MODULE, ["getProject"]),
    ...mapState(COMPANY_MANAGEMENT_MODULE, ["departments"]),
    ...mapGetters(`${PROJECT_MODULE}`, ["projectsList"]),
    loading() {
      const keys = ["categories", "departments"];
      return keys.some((key) => this.isApiLoading(key));
    },
    purchaseRequestId() {
      return this.$route.params.id;
    },
    filteredCategories() {
      return this.categoryTreeWithChildren(this.categoriesList);
    },
  },
  watch: {
    "currentValues.project": {
      immediate: true,
      deep: true,
      async handler(val) {
        if (val) {
          this.projectDetails = await this.fetchProject(val);
        }
      },
    },
  },

  methods: {
    ...mapActions(PURCHASE_REQUEST_MODULE, {
      editRequest: EDIT_PURCHASE_REQUEST,
    }),
    ...mapActions(PROJECT_MODULE, {
      fetchProject: GET_PROJECT,
    }),
    onModalClose() {
      this.closeModal();
      setTimeout(() => {
        this.category = null;
        this.department = null;
        this.project = null;
      }, 500);
    },
    save() {
      const updatedItem = {
        id: this.product,
      };
      if (this.category && this.category != this.currentValues.category) {
        updatedItem.category = this.category;
      }
      if (this.department && this.department != this.currentValues.department) {
        updatedItem.department = this.department;

        if (this.currentValues.requestedFor.type === "department") {
          updatedItem.requestedForId = this.department;
        }
      }
      if (this.project && this.project != this.currentValues.project) {
        updatedItem.project = this.project;

        if (this.currentValues.requestedFor.type === "project") {
          updatedItem.requestedForId = this.project;
        }
      }
      updatedItem.requestedForId =
        updatedItem.requestedForId || this.currentValues.requestedFor.object;
      const payload = {
        payload: { items: [updatedItem] },
        purchaseRequestId: this.purchaseRequestId,
      };
      this.setApiLoading({ key: "prEdit", value: true });
      this.editRequest(payload)
        .then(() => {
          this.onModalClose();
          this.showNotification(this.$t("toasts.genericSuccess"));
        })
        .catch((error) => this.showErrorMessage(error))
        .finally(() => {
          this.setApiLoading({ key: "prEdit", value: false });
        });
    },
  },
};
</script>
