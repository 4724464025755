<template>
  <div class="h-full flex flex-col">
    <div class="control-bar">
      <span class="group-buttons">
        <button type="button" class="rounded-l-md button" @click="zoomIn">
          <span class="sr-only">Previous</span>
          <ZoomInIcon class="h-5 w-5" aria-hidden="true" />
        </button>
        <button type="button" class="-ml-px button" @click="resetZoom">
          <span class="sr-only">Next</span>
          <ArrowsExpandIcon class="h-5 w-5" aria-hidden="true" />
        </button>
        <button
          type="button"
          class="-ml-px rounded-r-md button"
          @click="zoomOut"
        >
          <span class="sr-only">Next</span>
          <ZoomOutIcon class="h-5 w-5" aria-hidden="true" />
        </button>
      </span>
      <span class="group-buttons">
        <button type="button" class="rounded-l-md button" @click="rotateLeft">
          <span class="sr-only">Previous</span>
          <img
            :src="rotateLeftIcon"
            class="h-5 w-5 text-primary"
            alt="rotate left"
          />
        </button>
        <button
          type="button"
          class="-ml-px rounded-r-md button"
          @click="rotateRight"
        >
          <span class="sr-only">Next</span>
          <img
            :src="rotateRightIcon"
            class="h-5 w-5 text-primary"
            alt="rotate right"
          />
        </button>
      </span>
      <slot name="extraButtons" />
      <span v-if="showDownload" class="group-buttons">
        <button
          type="button"
          class="rounded-md button text-black"
          @click="downloadPDF"
        >
          <DownloadIcon
            class="xl:mr-2 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
          <span class="hidden xl:flex">Download</span>
        </button>
      </span>
    </div>
    <div v-if="loading" class="loading">
      <HivebuyLoader class="h-32" />
      <p
        class="flex justify-center text-base font-medium text-gray-600 animate-pulse mt-2"
      >
        {{ $t("invoice.loading") }}
      </p>
    </div>
    <template v-else>
      <div id="pdf-viewer-container" class="">
        <div v-for="page in pages" :key="page">
          <VuePDF
            :pdf="pdfdata"
            :rotation="rotate"
            :scale="scale"
            :page="page"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { VuePDF, usePDF } from "@tato30/vue-pdf";
import HivebuyLoader from "@/components/shared/HivebuyLoader";
import {
  ZoomInIcon,
  ZoomOutIcon,
  DownloadIcon,
  ArrowsExpandIcon,
} from "@heroicons/vue/solid";
import { downloadFileWithName } from "@/utils/utility_methods.js";

export default {
  components: {
    VuePDF,
    HivebuyLoader,
    ZoomInIcon,
    ZoomOutIcon,
    DownloadIcon,
    ArrowsExpandIcon,
  },
  props: {
    pdfLink: { type: [String, File], required: true },
    pdfName: { type: String, default: null },
    defaultResize: { type: Boolean, default: false },
    showDownload: { type: Boolean, default: false },
    defaultRotation: { type: Number, default: 0 },
    invoiceObject: { type: Object, default: () => ({}) },
    componentLoading: { type: Boolean, default: false },
  },
  data() {
    return {
      scale: "page-width",
      rotate: this.defaultRotation,
      pdfdata: null,
      pages: null,
      loading: this.componentLoading,
    };
  },
  computed: {
    rotateLeftIcon() {
      return require("@/assets/images/rotate-left.svg");
    },
    rotateRightIcon() {
      return require("@/assets/images/rotate-right.svg");
    },
    downloadFileName() {
      return this.invoiceObject?.title || this.pdfName;
    },
  },
  watch: {
    componentLoading(val) {
      this.loading = val;
    },
    pdfLink: {
      immediate: true,
      handler() {
        this.loadPdf();
      },
    },
  },
  mounted() {
    this.loadPdf();
    this.setInitialScale();
  },
  methods: {
    async loadPdf() {
      const { pdf, pages } = usePDF(this.pdfLink);
      this.pdfdata = pdf;
      this.pages = pages;
    },
    async downloadPDF() {
      try {
        await downloadFileWithName(this.pdfLink, this.downloadFileName);
      } catch (error) {
        this.showErrorMessage(error);
      }
    },
    setInitialScale() {
      const container = document.getElementById("pdf-viewer-container");
      const containerWidth = container
        ? container.offsetWidth
        : window.innerWidth;
      const pdfWidth = 600;
      this.scale = containerWidth / pdfWidth;
    },
    zoomIn() {
      this.scale += 0.25;
    },
    zoomOut() {
      if (this.scale > 0.25) this.scale -= 0.25;
    },
    resetZoom() {
      this.setInitialScale();
      this.rotate = this.defaultRotation;
    },
    rotateRight() {
      this.rotate = (this.rotate + 90) % 360;
    },
    rotateLeft() {
      this.rotate = this.rotate === 0 ? 270 : this.rotate - 90;
    },
  },
};
</script>

<style scoped>
#pdf-viewer-container {
  @apply flex-1 overflow-auto h-full;
}

.control-bar {
  @apply flex justify-between py-2 bg-gray-100 px-2 relative z-10;
  box-shadow: 0 5px 5px rgb(0, 0, 0, 0.1);
}

.group-buttons {
  @apply relative z-0 inline-flex shadow-sm rounded-md;
}

.button {
  @apply relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary;
}

.loading {
  @apply bg-gray-50 py-12 flex flex-col items-center justify-center;
  height: calc(100% - 53px);
}
</style>
