import { isObject } from "@/utils/utility_methods.js";
export const invoiceDashboardState = (state) => {
  return {
    default: {
      uploadSection: true,
      list: true,
      preview: false,
      form: false,
      poList: false,
    },
    preview: {
      uploadSection: false,
      list: true,
      preview: true,
      form: false,
      poList: false,
    },
    form: {
      uploadSection: false,
      list: false,
      preview: true,
      form: true,
      poList: false,
    },
    poList: {
      uploadSection: false,
      list: false,
      preview: true,
      form: false,
      poList: true,
    },
  }[state];
};

export const permitParams = (invoicePurchaseOrders) => {
  const records = [];
  invoicePurchaseOrders.forEach((invoicePurchaseOrder) => {
    if (Object.keys(invoicePurchaseOrder).includes("id")) {
      const {
        id,
        totalNetAmount,
        totalGrossAmount,
        totalGrossAmountCurrency,
        totalNetAmountCurrency,
        tax,
        shipping,
        totalAmount,
      } = invoicePurchaseOrder;
      records.push({
        id,
        totalNetAmount,
        totalGrossAmount,
        totalGrossAmountCurrency,
        totalNetAmountCurrency,
        tax,
        shipping,
        totalAmount,
      });
    } else {
      const {
        invoice,
        notes,
        purchaseOrder,
        shipping,
        shippingCurrency,
        tax,
        taxCurrency,
        totalAmount,
        totalAmountCurrency,
        totalGrossAmount,
        totalGrossAmountCurrency,
        totalNetAmount,
        totalNetAmountCurrency,
      } = invoicePurchaseOrder;
      const payload = {
        invoice,
        notes,
        purchaseOrder: purchaseOrder.id,
        shipping,
        shippingCurrency,
        tax,
        taxCurrency,
        totalAmount,
        totalAmountCurrency,
        totalGrossAmount,
        totalGrossAmountCurrency,
        totalNetAmount,
        totalNetAmountCurrency,
      };
      records.push(payload);
    }
  });
  return records;
};
const invoicePoPending = (invoice) => {
  return (
    invoice?.invoicePurchaseOrders?.filter((ipo) =>
      ["P", "RV", "O", "A"].includes(ipo.status)
    ).length < 1
  );
};
const invoicePending = (invoice) => {
  return ["A", "P", "NI", "R"].includes(invoice?.status);
};
const ocrInProgress = (invoice) => {
  return invoice?.status == "N" && invoice.ocrStatus == "S";
};
export const isAllowedToAssign = (invoice) => {
  return (
    collectiveInvoiceAllowPOAddition(invoice) &&
    !invoicePending(invoice) &&
    !ocrInProgress(invoice) &&
    !invoice.isInvoiceLevelApprover
  );
};

export const isAllowedToEdit = (invoice) => {
  return !invoicePending(invoice) && !ocrInProgress(invoice);
};
export const collectiveInvoiceAllowPOAddition = (invoice) => {
  if (invoice.collective) return true;
  return invoicePoPending(invoice);
};
const getFiltersData = (filter) => {
  return {
    all: {},
    open: {
      status: "O",
    },
    new: {
      status: "N",
    },
    pendingApproval: {
      status: "P",
    },
    approved: {
      status: "A",
      po__isnull: false,
    },
    notAnInvoice: {
      status: "NI",
      po__isnull: false,
    },
    askedForRevision: {
      status: "RV",
      po__isnull: false,
    },
    rejected: {
      status: "R",
      po__isnull: false,
    },
    paid: {
      invoice_paid: true,
    },
    complete: {
      complete: true,
    },
  }[filter];
};
const mappedKeys = {
  legalEntity: "legal_entity",
  companySupplier: "company_supplier__id",
  supplierNumber: "company_supplier__supplier_number__contains",
  amountstart: "total_net_amount__gte",
  amountend: "total_net_amount__lte",
};
export const mapFilterKeys = (filters) => {
  const filtersHash = { ...filters, ...getFiltersData(filters.filter) };
  Object.keys(filtersHash).forEach((key) => {
    const value = filtersHash[key];
    const orgKey = key;
    delete filtersHash[orgKey];

    if (isObject(value)) {
      Object.keys(value).forEach((valueKey) => {
        if (value[valueKey])
          filtersHash[mappedKeys[`${orgKey}${valueKey}`]] = value[valueKey];
      });
    } else {
      const mappedKey = mappedKeys[orgKey] ? mappedKeys[orgKey] : orgKey;
      if (value) filtersHash[mappedKey] = value;
    }
  });

  return filtersHash;
};
