<template>
  <div id="supplier">
    <div
      v-if="isApiLoading('companyIntegratedSuppliers')"
      class="flex items-center"
    >
      <HivebuyLoader class="h-40" />
    </div>
    <div v-else>
      <div>
        <h3 class="page-title">
          {{ $t("companyManagement.connectedSupplier.title") }}
        </h3>
      </div>

      <ul role="list">
        <li v-for="supplier in integratedSuppliersList()" :key="supplier.id">
          <Supplier :supplier="supplier.supplier" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Supplier from "@/components/CompanyManagement/ConnectedSuppliers/Supplier";
import { HivebuyLoader } from "@/components/shared/index";
import loadIntegratedSuppliersMixin from "@/components/mixins/loadIntegratedSuppliersMixin.js";
export default {
  components: {
    Supplier,
    HivebuyLoader,
  },
  mixins: [loadIntegratedSuppliersMixin],
};
</script>
