import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
import { PURCHASE_REQUEST_MODULE } from "@/store/PurchaseRequest/types";
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(PURCHASE_REQUEST_MODULE, ["newRequestItem", "isValidItemInCart"]),
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, ["categoryExists"]),
  },
  methods: {
    isValidCatalogueItem(item) {
      const { companySupplier, unlistedSupplier, category } = item;

      if (!item.active) {
        return false;
      }

      if (!this.categoryExists(category)) {
        return false;
      }

      if (!companySupplier && !unlistedSupplier) {
        return false;
      }

      return true;
    },
    allowSameCategoryAndSupplier(item) {
      const { companySupplier, unlistedSupplier, category } = item;
      const firstItem = this.newRequestItem(0);
      if(!this.isValidItemInCart) { return true }
      if (this.isFeatureAllowed("itemLevelApproval") || !firstItem) return true;

      const firstCompanySupplier =
        firstItem.companySupplier || firstItem.unlistedSupplier;
      const itemSupplier = companySupplier || unlistedSupplier;

      return (
        firstItem.category == category && firstCompanySupplier == itemSupplier
      );
    },
  },
};
