<template>
  <Panel
    :id="index"
    class="panel-header"
    toggleable
    :pt="{
      root: 'hb-panel-root-parent',
      toggler: 'hb-panel-toggler',
    }"
  >
    <template #togglericon="{ collapsed }">
      <div class="">
        <ChevronDownIcon
          class="h-6 w-6 transition-transform"
          :class="collapsed ? '' : 'rotate-180'"
        />
      </div>
    </template>
    <template #header>
      <div v-auto-animate class="w-full py-2">
        <div
          v-if="deleteConfirmation"
          class="md:flex md:items-center md:justify-between text-red-500 w-full text-base font-medium"
        >
          <div class="min-w-0 flex-1">
            {{ $t("purchaseRequest.summary.deleteConfirmDialog.text") }}
          </div>

          <div class="mt-4 flex md:ml-4 md:mt-0 mr-2 md:mr-4">
            <button
              type="button"
              class="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              @click="deleteConfirmationToggle"
            >
              {{ $t("buttons.cancel") }}
            </button>
            <button
              type="button"
              class="ml-3 inline-flex items-center rounded-md !bg-hivebuy-red px-3 py-2 text-sm font-semibold text-white shadow-sm hover:ring-1 hover:ring-offset-2 hover:ring-hivebuy-red focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              @click="
                () => {
                  saveRequestAttributes({ index, action: 'delete' });
                  deleteConfirmationToggle();
                }
              "
            >
              {{ $t("buttons.delete") }}
            </button>
          </div>
        </div>
        <div v-else>
          <div
            class="min-w-0"
            :class="
              panelCollapsed
                ? 'grid grid-cols-4 gap-x-2'
                : 'flex justify-between gap-x-2'
            "
          >
            <div
              :class="[{ 'border-hivebuy-red rounded': showErrorBoundary }]"
              class="text-primary font-semibold col-span-2 line-clamp-1 flex items-center gap-x-2"
            >
              <ItemSourceTag :list="[item.sourceType]" />
              {{ item.name || "-" }}
            </div>
            <div
              v-if="panelCollapsed"
              class="text-base font-medium flex items-center gap-x-1"
            >
              <i18n-n
                v-if="item.netAmount"
                :value="parseFloat(item.netAmount)"
                format="currency"
                :locale="currencyToLocale(item.netAmountCurrency)"
              />
              <div
                v-if="item.quantity > 1"
                class="flex items-center text-sm font-normal"
              >
                (x{{ item.quantity }})
              </div>
            </div>
            <div
              class="flex items-center gap-x-1 rounded-full py-1 px-1 border border-transparent hover:border-gray-300 hover:bg-gray-100 mr-2 md:mr-6 justify-center cursor-pointer text-hivebuy-red/60 hover:text-hivebuy-red"
              :class="panelCollapsed ? 'justify-self-end ' : ''"
              @click="deleteConfirmationToggle"
            >
              <TrashIcon class="w-[1.2rem] h-[1.2rem]" aria-hidden="true" />
            </div>
          </div>
        </div>
      </div>
    </template>
    <section class="flex flex-col gap-y-6">
      <div
        v-if="isFeatureAllowed('itemLevelApproval')"
        class="flex rounded-xl border border-[#e6e7eb] bg-white py-3 px-1"
      >
        <RequestHeader
          v-model="item"
          :v="v$"
          @default-values-change="handleDefaultValues"
        />
      </div>
      <ItemBasicInfo
        ref="basicInfo"
        v-model="item"
        :v="v$"
        :error="basicFieldErrors"
        class="pt-4"
      />
      <AdvanceInfo
        ref="advanceInfo"
        v-model="item"
        :v="v$"
        :error="advanceFieldErrors"
        class="pt-4"
      />
    </section>
  </Panel>
</template>

<script>
import {
  ItemBasicInfo,
  AdvanceInfo,
} from "@/components/RequestCreation/index.js";
import { newRequestMixin } from "@/components/mixins/index.js";
import { TrashIcon } from "@heroicons/vue/solid";
import useValidate from "@vuelidate/core";
import { required, requiredIf, helpers } from "@vuelidate/validators";
import { newRequestedForValidation } from "@/utils/helpers/purchaseRequestHelper";
import { currencyToLocale, deepClone } from "@/utils/utility_methods.js";
import { greaterThanZero } from "@/utils/helpers/purchaseRequestHelper";
import { COST_RECURRENCE_TYPES } from "@/utils/constants.js";
import ItemSourceTag from "@/components/RequestCreation/ItemSourceTag.vue";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
import { mapGetters, mapState } from "vuex";
import { RequestHeader } from "@/components/RequestCreation";
import Panel from "primevue/panel";
import { ChevronDownIcon } from "@heroicons/vue/solid";
export default {
  name: "ItemForm",
  components: {
    Panel,
    ItemBasicInfo,
    TrashIcon,
    AdvanceInfo,
    ItemSourceTag,
    RequestHeader,
    ChevronDownIcon,
  },
  mixins: [newRequestMixin],
  provide() {
    return {
      index: this.index,
    };
  },
  props: {
    index: {
      type: Number,
      default: 0,
    },
    setValidationObject: {
      type: Function,
      default: () => {},
    },
  },

  data() {
    return {
      deleteConfirmation: false,
      panelCollapsed: false,
      v$: useValidate(),
    };
  },

  computed: {
    ...mapState(COMPANY_MANAGEMENT_MODULE, ["customFields"]),
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, ["getCustomFieldsBySource"]),
    showErrorBoundary() {
      if (this.isFeatureAllowed("itemLevelApproval")) return this.v$.$error;
      const nonItemFields = ["category", "companySupplier"];
      const errors = this.v$.$errors.map((error) => error.$property);
      return !!errors.filter((item) => !nonItemFields.includes(item)).length;
    },
    item: {
      get() {
        return this.newRequestItem(this.index) || { price: {} };
      },
      set(value) {
        this.saveRequestAttributes({ item: value, index: this.index });
      },
    },
    basicFieldErrors() {
      const fields = [
        "name",
        "category",
        "companySupplier",
        "unit",
        "quantity",
        "netAmount",
        "netGross",
        "netAmountCurrency",
      ];
      const errors = this.v$?.$errors?.map((error) => error.$property);
      return fields.some((field) => errors.includes(field));
    },
    advanceFieldErrors() {
      return this.v$.$error && !this.basicFieldErrors;
    },
    customFieldSource() {
      return this.item?.isCatalogueItem
        ? "catalogueUserInput"
        : this.item?.isIntegratedSupplierItem
        ? "integrated"
        : "freeForm";
    },
  },

  watch: {
    item: {
      handler(newVal, oldVal) {
        const compareKeys = ["isCatalogueItem", "isIntegratedSupplierItem"];
        if (
          oldVal &&
          compareKeys.every((key) => newVal?.[key] == oldVal?.[key])
        )
          return false;
        this.item.customFields = this.itemCustomFields();
      },
      immediate: true,
      deep: true,
    },
    "item.companyAddress": {
      handler(newVal) {
        if (this.isFeatureAllowed("itemLevelApproval")) return;
        this.copyValueToAllItems("companyAddress", newVal);
      },
    },
    "item.invoiceAddress": {
      handler(newVal) {
        if (this.isFeatureAllowed("itemLevelApproval")) return;
        this.copyValueToAllItems("invoiceAddress", newVal);
      },
    },
  },
  mounted() {
    if (!this.isFeatureAllowed("itemLevelApproval") && !this.index) {
      this.setValidationObject(this.v$.item);
    }
  },
  methods: {
    currencyToLocale,
    handleDefaultValues(response) {
      const { paymentMethod, invoiceAddress, deliveryAddress } = response;
      this.item = {
        ...this.item,
        companyAddress: deliveryAddress,
        paymentMethod,
        invoiceAddress,
      };
    },
    async validateRequestData() {
      const response = await this.v$.$validate();
      if (!response) {
        if (this.basicFieldErrors) this.$refs.basicInfo.togglePanel(false);
        if (this.advanceFieldErrors) this.$refs.advanceInfo.togglePanel(false);
      }
      return response;
    },
    deleteConfirmationToggle() {
      this.deleteConfirmation = !this.deleteConfirmation;
    },

    itemCustomFields() {
      const sourceCustomFields = [
        ...deepClone(this.getCustomFieldsBySource(this.customFieldSource)),
      ];

      this.item.customFields?.forEach((field) => {
        const itemFieldIndex = sourceCustomFields.findIndex(
          (itemField) => itemField.name === field.name
        );

        if (itemFieldIndex > -1) {
          sourceCustomFields[itemFieldIndex].value = field.value;
        } else if (this.item.isCatalogueItem) {
          const newField = Object.assign({}, field, {
            readOnly: true,
            id: this.customFields.find((cf) => cf.name === field.name)?.id,
          });
          sourceCustomFields.push(newField);
        }
      });
      return sourceCustomFields.map((field) => {
        field.customField = field.id;
        delete field.id;
        return field;
      });
    },
  },
  validations() {
    return {
      item: {
        name: { required },
        category: { required },
        companySupplier: {
          required: requiredIf(() => {
            if (this.item.companySupplier) return false;
            if (this.item.unlistedSupplier) return false;
            if (this.item.skipSupplier) return false;
            return true;
          }),
        },
        unit: { required },
        quantity: {
          required,
          minValue: greaterThanZero,
        },
        netAmount: { required, minValue: greaterThanZero },
        netGross: { required },
        netAmountCurrency: { required },
        paymentMethod: {
          required: requiredIf(() => !this.item.isIntegratedSupplierItem),
        },
        invoiceAddress: { required },
        companyAddress: { required },
        requestedForId: { required },
        requestedForType: { required },
        recurrence: {
          validTo: {
            required: requiredIf(() =>
              [
                COST_RECURRENCE_TYPES.monthly,
                COST_RECURRENCE_TYPES.yearly,
              ].includes(this.item.recurrence.interval)
            ),
          },
          servicePeriodStart: {
            required: requiredIf(
              () =>
                this.item.recurrence.interval ==
                COST_RECURRENCE_TYPES.servicePeriod
            ),
          },
          servicePeriodEnd: {
            required: requiredIf(
              () =>
                this.item.recurrence.interval ==
                COST_RECURRENCE_TYPES.servicePeriod
            ),
          },
        },
        department: {
          required: requiredIf(() =>
            newRequestedForValidation(this.item, "department")
          ),
        },
        project: {
          required: requiredIf(() =>
            newRequestedForValidation(this.item, "project")
          ),
        },
        customFields: {
          $each: helpers.forEach({
            value: {
              required: requiredIf(
                (_value, object) =>
                  this.isFeatureAllowed("customFields") &&
                  object.mandatoryField &&
                  !object.readOnly
              ),
            },
          }),
        },
      },
    };
  },
};
</script>

<style>
.hb-panel-header {
  @apply bg-white flex items-center gap-x-4 justify-between w-full;
}

.hb-panel-toggler {
  @apply flex items-center;
}

.hb-panel-root-parent .p-panel-header {
  @apply !bg-[#f9fafc] !border-none border-transparent;
}
.hb-panel-root-parent {
  @apply border border-gray-300 rounded;
}
.hb-panel-root-parent .error {
  @apply border-hivebuy-red;
}

.hb-panel-root-parent .p-toggleable-content .p-panel-content {
  @apply bg-[#f9fafc] p-4 border-none;
}

.hb-panel-root {
  @apply self-stretch p-4 bg-white rounded-2xl border border-[#e6e7eb] flex-col justify-center items-start flex;
}

.navigation-icon {
  @apply w-8 h-8 hover:text-primary cursor-pointer;
}

:deep(.p-panel-header) {
  @apply bg-gray-50 !important;
}

.panel-header {
  scroll-margin: 140px;
}
[data-pc-section="toggleablecontent"] {
  @apply w-full;
}
</style>
