export default {
  invoiceWithId: (state) => (id) => {
    return state.invoicesObject[id];
  },
  invoiceList: (state) => (filter) => {
    return (
      state.invoices[filter].map(
        (invoiceId) => state.invoicesObject[invoiceId]
      ) || []
    );
  },
};
