import { deleteObject, get, patch, post } from "../api";
import { mapNewRequest } from "@/utils/helpers/purchaseRequestHelper.js";
import {
  PURCHASE_REQUEST_API_URL,
  LINK_PREVIEW_URL,
  LINK_PREVIEW_KEY,
  PURCHASE_REQUEST_PREVIEW_API_URL,
  PURCHASE_ORDER_INVOICES_URL,
  PR_ANALYTICS_URL,
  DEPT_ANALYTICS_URL,
  PURCHASE_REQUEST_ITEM_STATUS_UPDATE_API_URL,
  ORDER_ITEM_STATUS,
  ACTIONABLE_PURCHASE_REQUEST_API_URL,
  MY_PURCHASE_REQUEST_URL,
  RESTORE_PR_ITEM,
  CHANGE_SHOPPING_LIST_RELATION_URL,
  COST_SAVING_URL,
  ACCRUAL_REPORT_URL,
  SEARCH_ITEMS,
  EXCHANGE_RATE,
  VALIDATION_API,
  NEW_REQUEST_CREATION_API,
  DRAFT_PR_URL,
} from "@/utils/services/apiUrls.js";
import { formattedURL, filterZeroValues } from "@/utils/utility_methods";
import * as PurchaseRequestTypes from "@/store/PurchaseRequest/types.js";
import * as DashboardTypes from "@/store/Dashboard/types.js";
import {
  SHOPPING_LIST_MODULE,
  SET_SHOPPING_LIST,
} from "@/store/ShoppingList/types.js";
import {
  SET_PURCHASE_REQUESTS_ANALYTICS,
  SET_DEPARTMENT_ANALYTICS,
} from "@/store/Dashboard/types.js";
import { CATALOGUE_ITEM_SOURCE } from "@/utils/constants";
import store from "@/store";
import camelcaseKeys from "camelcase-keys";
import { zipFileHeaders } from "@/utils/services/setup/requestConstants.js";
import { isObjectEmpty } from "@/utils/utility_methods.js";
import { updatePaginationParams } from "@/utils/helpers/paginationHelper";
import { UPDATE_CURRENT_REQUEST_WITH_PREVIEW_DATA } from "@/store/PurchaseRequest/types.js";
import { formattedUrlApi } from "@/utils/utility_methods";
const requestQueryParams = (filters) => {
  return isObjectEmpty(filters.advancedFilters)
    ? filters.paginationParams
    : Object.assign({}, filters.paginationParams, filters.advancedFilters);
};
const getListAfterCreate = (state, commit, resolve, reject) => {
  const queryParams = requestQueryParams(state.listFilters);

  return getPurchaseRequestsList(commit, resolve, reject, queryParams, true);
};
const saveStoreState = (commit, payload) => {
  commit(PurchaseRequestTypes.SET_REQUEST_DETAILS, payload);
  commit(
    `${DashboardTypes.DASHBOARD_MODULE}/${DashboardTypes.SET_ACTION_REQUIRED_PURCHASE_REQUEST}`,
    payload,
    {
      root: true,
    }
  );
};
export const addPurchaseRequest = (
  payload,
  preview,
  commit,
  state,
  resolve,
  reject
) => {
  const url = preview
    ? PURCHASE_REQUEST_PREVIEW_API_URL
    : PURCHASE_REQUEST_API_URL;
  post(url, payload)
    .then((response) => {
      if (!preview) {
        getListAfterCreate(state, commit, resolve, reject);
      } else {
        commit(UPDATE_CURRENT_REQUEST_WITH_PREVIEW_DATA, response.data);
      }
      console.log("response", response);
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
};

export const getPurchaseRequestsAnalytics = (
  commit,
  resolve,
  reject,
  noOfRecords
) => {
  let params = { days: null };
  if (noOfRecords) {
    params = { days: noOfRecords };
  }
  get(PR_ANALYTICS_URL, { params })
    .then((response) => {
      commit(SET_PURCHASE_REQUESTS_ANALYTICS, response.data);
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};
export const getDepartmentAnalytics = (
  commit,
  resolve,
  reject,
  noOfRecords
) => {
  let params = { days: null };
  if (noOfRecords) {
    params = { days: noOfRecords };
  }
  get(DEPT_ANALYTICS_URL, { params })
    .then((response) => {
      const department = filterZeroValues(response.data).map((element) => {
        return {
          name: element.name,
          amount: parseFloat(element.amount),
          category: filterZeroValues(element.category, "totalNetAmount").map(
            (element) => {
              return {
                name: element.category,
                amount: element.totalNetAmount,
                numberOfPO: element.purchaseOrders,
              };
            }
          ),
        };
      });
      commit(SET_DEPARTMENT_ANALYTICS, department);
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};
export const getPurchaseRequests = (
  commit,
  resolve,
  reject,
  paginationParams
) => {
  commit(PurchaseRequestTypes.SET_PURCHASE_REQUESTS, null);
  get(formattedURL(PURCHASE_REQUEST_API_URL, paginationParams))
    .then((response) => {
      const results = response.data.results || response.data;
      updatePaginationParams("listFilters", response.data);
      commit(PurchaseRequestTypes.SET_PURCHASE_REQUESTS, results);
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};

export const getPurchaseRequestsList = (
  commit,
  resolve,
  reject,
  queryParams,
  areFiltersUpdated
) => {
  get(formattedURL(PURCHASE_REQUEST_API_URL, queryParams))
    .then((response) => {
      const results = response.data.results || response.data;
      updatePaginationParams("listFilters", response.data);
      commit(PurchaseRequestTypes.SET_REQUEST_LIST_DETAILS, results);
      commit(PurchaseRequestTypes.SET_PURCHASE_REQUESTS_LIST, {
        payload: results,
        areFiltersUpdated,
      });
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};

export const getMyPurchaseRequests = (
  commit,
  resolve,
  reject,
  requestParams
) => {
  get(formattedURL(MY_PURCHASE_REQUEST_URL, requestParams))
    .then((response) => {
      commit(
        `${PurchaseRequestTypes.PURCHASE_REQUEST_MODULE}/${PurchaseRequestTypes.SET_REQUEST_LIST_DETAILS}`,
        response.data.results,
        { root: true }
      );
      commit(DashboardTypes.SET_MY_PURCHASE_REQUESTS, response.data);
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};

export const downloadInvoices = (resolve, reject, requestParams) => {
  get(formattedURL(PURCHASE_ORDER_INVOICES_URL, requestParams), {
    headers: zipFileHeaders(),
    responseType: "arraybuffer",
  })
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
};
export const downloadAccrualReport = (resolve, reject) => {
  get(ACCRUAL_REPORT_URL)
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
};

export const urlData = (link, resolve, reject) => {
  const url = `${LINK_PREVIEW_URL}?key=${LINK_PREVIEW_KEY}&q=${link}`;
  get(url)
    .then((response) => {
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};

export const getPurchaseRequest = (
  purchaseRequestId,
  commit,
  resolve,
  reject
) => {
  if (!purchaseRequestId) return;

  get(`${PURCHASE_REQUEST_API_URL}${purchaseRequestId}/`)
    .then((response) => {
      saveStoreState(commit, response.data);
      resolve(camelcaseKeys(response.data, { deep: true }));
    })
    .catch((err) => {
      reject(err);
    });
};
export const deletePurchaseRequest = (purchaseRequestId, resolve, reject) => {
  if (!purchaseRequestId) return;

  deleteObject(`${PURCHASE_REQUEST_API_URL}${purchaseRequestId}/`)
    .then((response) => {
      resolve(camelcaseKeys(response.data, { deep: true }));
    })
    .catch((err) => {
      reject(err);
    });
};

export const updatePurchaseRequestStatus = (
  commit,
  purchaseRequestId,
  resolve,
  reject,
  payload
) => {
  if (!purchaseRequestId) return;

  patch(
    `${PURCHASE_REQUEST_API_URL}${purchaseRequestId}/update-status/`,
    payload
  )
    .then((response) => {
      const { purchaseRequest } = response.data;
      saveStoreState(commit, purchaseRequest);
      resolve(purchaseRequest);
    })
    .catch((err) => {
      reject(err);
    });
};

export const updatePurchaseRequest = (
  purchaseRequestId,
  commit,
  resolve,
  reject,
  payload
) => {
  if (!purchaseRequestId) return;

  patch(`${PURCHASE_REQUEST_API_URL}${purchaseRequestId}/`, payload)
    .then((response) => {
      saveStoreState(commit, response.data);
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};

export const updatePurchaseRequestItemStatus = (
  payload,
  commit,
  resolve,
  reject
) => {
  post(PURCHASE_REQUEST_ITEM_STATUS_UPDATE_API_URL, payload)
    .then((response) => {
      saveStoreState(commit, response.data.purchaseRequest);
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};

export const restorePurchaseRequestItem = (id, commit, resolve, reject) => {
  post(RESTORE_PR_ITEM(id))
    .then((response) => {
      saveStoreState(commit, response.data.purchaseRequest);
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};
export const updatePurchaseRequestItem = (payload, commit, resolve, reject) => {
  post(ORDER_ITEM_STATUS, payload)
    .then((response) => {
      saveStoreState(commit, response.data.purchaseRequest);
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};

export const setCostSaving = (data, commit, resolve, reject) => {
  const { payload, costSaving } = data;
  const action = costSaving
    ? patch(`${COST_SAVING_URL}${costSaving.id}/`, payload)
    : post(COST_SAVING_URL, payload);
  action
    .then((response) => {
      saveStoreState(commit, response.data);
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};

export const getUserActionRequiredPurchaseRequests = (
  commit,
  resolve,
  reject,
  paginationParams
) => {
  get(formattedURL(ACTIONABLE_PURCHASE_REQUEST_API_URL, paginationParams))
    .then((response) => {
      commit(
        `${PurchaseRequestTypes.PURCHASE_REQUEST_MODULE}/${PurchaseRequestTypes.SET_REQUEST_LIST_DETAILS}`,
        response.data.results,
        { root: true }
      );
      commit(
        DashboardTypes.SET_ACTION_REQUIRED_PURCHASE_REQUESTS,
        response.data
      );
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};

export const changeShoppingListRelation = (
  method,
  payload,
  commit,
  resolve,
  reject
) => {
  patch(`${CHANGE_SHOPPING_LIST_RELATION_URL}?method=${method}`, payload)
    .then((response) => {
      saveStoreState(commit, response.data.purchaseRequest);
      commit(
        `${SHOPPING_LIST_MODULE}/${SET_SHOPPING_LIST}`,
        response.data.shoppingList,
        {
          root: true,
        }
      );
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};

export const getDefaultValuesForRequestedFor = (
  commit,
  resolve,
  reject,
  payload
) => {
  const updatedPayload = { requestedFor: payload };
  post(`${PURCHASE_REQUEST_API_URL}default-values/`, updatedPayload)
    .then((response) => {
      const mutationPayload = {
        id: payload.id,
        defaultValues: response.data,
      };
      commit(
        PurchaseRequestTypes.SET_DEFAULT_VALUES_FOR_REQUESTED_FOR,
        mutationPayload
      );
      resolve(response.data);
    })
    .catch((err) => reject(err));
};

export const searchItems = (resolve, reject, search) => {
  get(`${SEARCH_ITEMS}?search=${search}`)
    .then((response) => {
      resolve(response.data);
    })
    .catch((err) => reject(err));
};
export const getExchangeRate = (resolve, reject, currency) => {
  let url = EXCHANGE_RATE;
  if (currency) url = `${url}?currency=${currency}`;
  get(url)
    .then((response) => {
      resolve(response.data);
    })
    .catch((err) => reject(err));
};

export const validateRequestData = (resolve, reject, data) => {
  post(VALIDATION_API, mapNewRequest(data))
    .then((response) => {
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};

export const createNewRequest = (resolve, reject, commit, data) => {
  post(NEW_REQUEST_CREATION_API, mapNewRequest(data))
    .then((response) => {
      commit(PurchaseRequestTypes.REMOVE_DRAFT_REQUEST, data.id);
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};
export const markDraft = (resolve, reject, commit, data) => {
  const id = data.id;
  const method = id ? patch : post;
  const url = id ? `${DRAFT_PR_URL}${id}/` : DRAFT_PR_URL;
  method(url, mapNewRequest(data))
    .then((response) => {
      commit(PurchaseRequestTypes.SET_DRAFT_LIST, response.data);
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};
export const getDraftList = (resolve, reject, params, commit) => {
  get(formattedUrlApi(DRAFT_PR_URL, params))
    .then((response) => {
      commit(PurchaseRequestTypes.SET_DRAFT_LIST, response.data.results);
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
};
const formatItems = (data) => {
  const getSourceType = store.getters["AUTH_MODULE/modelId"];
  const { CATALOGUE_ITEM, SUPPLIER } = CATALOGUE_ITEM_SOURCE;
  const newData = Object.assign({}, data);
  newData.items = data.items.map((item) => {
    item.sourceType = parseInt(item.sourceType);
    item.isCatalogueItem = item.sourceType == getSourceType(CATALOGUE_ITEM);
    item.isIntegratedSupplierItem = item.sourceType == getSourceType(SUPPLIER);
    return item;
  });
  return newData;
};
export const getDraftRequest = (resolve, reject, id, commit) => {
  get(`${DRAFT_PR_URL}${id}/`)
    .then((response) => {
      commit(PurchaseRequestTypes.SAVE_NEW_REQUEST_ATTRIBUTES, {
        action: "reset",
        object: formatItems(response.data),
      });
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};
