// Modules
export const COMPANY_MANAGEMENT_MODULE = "COMPANY_MANAGEMENT_MODULE";

// Actions
export const ADD_INVOICE = "ADD_INVOICE";
export const GET_INVOICES = "GET_INVOICES";
export const GET_INVOICES_WITHOUT_PAGINATION =
  "GET_INVOICES_WITHOUT_PAGINATION";
export const DELETE_INVOICE = "DELETE_INVOICE";
export const UPDATE_INVOICE = "UPDATE_INVOICE";
export const DOWNLOAD_PDF = "DOWNLOAD_PDF";
export const UPDATE_INVOICE_RELATION = "UPDATE_INVOICE_RELATION";
export const ACTIONABLE_INVOICES = "ACTIONABLE_INVOICES";
export const SET_ACTIONABLE_INVOICES_FILTERS =
  "SET_ACTIONABLE_INVOICES_FILTERS";
export const ASSIGN_PURCHASE_ORDER = "ASSIGN_PURCHASE_ORDER";

export const ADD_DEPARTMENT = "ADD_DEPARTMENT";
export const GET_DEPARTMENT = "GET_DEPARTMENT";
export const GET_DEPARTMENTS = "GET_DEPARTMENTS";
export const DELETE_DEPARTMENT = "DELETE_DEPARTMENT";
export const UPDATE_DEPARTMENT = "UPDATE_DEPARTMENT";

export const ADD_PAYMENT_METHOD = "ADD_PAYMENT_METHOD";
export const GET_PAYMENT_METHODS = "GET_PAYMENT_METHODS";
export const DELETE_PAYMENT_METHOD = "DELETE_PAYMENT_METHOD";
export const UPDATE_PAYMENT_METHOD = "UPDATE_PAYMENT_METHOD";
export const GET_PAYMENT_METHOD = "GET_PAYMENT_METHOD";

export const UPDATE_COMPANY = "UPDATE_COMPANY";
export const GET_COMPANY = "GET_COMPANY";
export const GET_COST_SAVING = "GET_COST_SAVING";
export const UPDATE_COMPANY_ADDRESS = "UPDATE_COMPANY_ADDRESS";
export const GET_COMPANY_ADDRESSES = "GET_COMPANY_ADDRESSES";
export const DELETE_COMPANY_ADDRESS = "DELETE_COMPANY_ADDRESS";
export const ADD_COMPANY_ADDRESS = "ADD_COMPANY_ADDRESS";

export const GET_CATEGORIES = "GET_CATEGORIES";
export const GET_CATEGORY = "GET_CATEGORY";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const ADD_CATEGORY = "ADD_CATEGORY";
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const SAVE_CATEGORY = "SAVE_CATEGORY";

export const GET_USERS = "GET_USERS";
export const GET_USERS_LIST = "GET_USERS_LIST";

export const DELETE_USER = "DELETE_USER";
export const INVITE_USER = "INVITE_USER";
export const RESEND_INVITE = "RESEND_INVITE";
export const UPDATE_USER = "UPDATE_USER";
export const GET_PENDING_USERS = "GET_PENDING_USERS";
export const CHANGE_USER_STATUS = "CHANGE_USER_STATUS";
export const ASSIGN_USER_SUBSTITUTE = "ASSIGN_USER_SUBSTITUTE";
export const GET_USER = "GET_USER";

export const FETCH_COMPANY_SUPPLIER = "FETCH_COMPANY_SUPPLIER";
export const GET_COMPANY_INTEGRATED_SUPPLIERS =
  "GET_COMPANY_INTEGRATED_SUPPLIERS";
export const GET_SUPPLIER_URL = "GET_SUPPLIER_URL";
export const GET_COMPANY_SUPPLIERS = "GET_COMPANY_SUPPLIERS";
export const SAVE_COMPANY_SUPPLIER = "SAVE_COMPANY_SUPPLIER";
export const SAVE_INTEGRATED_SUPPLIER = "SAVE_INTEGRATED_SUPPLIER";
export const DELETE_COMPANY_SUPPLIER = "DELETE_COMPANY_SUPPLIER";
export const VERIFY_SUPPLIER_CREDENTIALS = "VERIFY_SUPPLIER_CREDENTIALS";

export const GET_FILTERED_COMPANY_SUPPLIERS = "GET_FILTERED_COMPANY_SUPPLIERS";

export const AUTHORISE_SUPPLIER_CREDENTIALS = "AUTHORISE_SUPPLIER_CREDENTIALS";

export const UPDATE_COMPANY_SETTING = "UPDATE_COMPANY_SETTING";

export const ADD_WORKFLOW = "ADD_WORKFLOW";
export const UPDATE_WORKFLOW = "UPDATE_WORKFLOW";
export const GET_WORKFLOWS = "GET_WORKFLOWS";
export const GET_WORKFLOW = "GET_WORKFLOW";
export const DELETE_WORKFLOW = "DELETE_WORKFLOW";
export const SET_WORKFLOWS_ORDER = "SET_WORKFLOWS_ORDER";
export const SET_PUR_WORKFLOWS = "SET_PUR_WORKFLOWS";
export const SET_INVOICE_WORKFLOWS = "SET_INVOICE_WORKFLOWS";

export const GET_GLOBAL_POLICIES = "GET_GLOBAL_POLICIES";
export const UPDATE_GLOBAL_POLICY = "UPDATE_GLOBAL_POLICY";

export const GET_BUDGETS = "GET_BUDGETS";
export const UPDATE_BUDGETS = "UPDATE_BUDGETS";
export const UPDATE_BUDGET = "UPDATE_BUDGET";

export const DELETE_PENDING_USER = "DELETE_PENDING_USER";
export const UPLOAD_CSV = "UPLOAD_CSV";
export const FINALIZE_CSV = "FINALIZE_CSV";
export const DOWNLOAD_CSV = "DOWNLOAD_CSV";

export const GET_SVIX_LOGIN_DATA = "GET_SVIX_LOGIN_DATA";
export const GET_OCR_DATA = "GET_OCR_DATA";

export const GET_QUICK_LINKS = "GET_QUICK_LINKS";

export const ADD_DATEV_CREDENTIALS = "ADD_DATEV_CREDENTIALS";
export const CONFIRM_DATEV_CONNECTION = "CONFIRM_DATEV_CONNECTION";
export const DISCONNECT_DATEV = "DISCONNECT_DATEV";
export const GET_DATEV_ACCOUNT_CONSULTANTS = "GET_DATEV_ACCOUNT_CONSULTANTS";
export const SET_DATEV_CONSULTANT_ID = "SET_DATEV_CONSULTANT_ID";
export const SET_DATEV_COLLECTIVE_CREDITOR = "SET_DATEV_COLLECTIVE_CREDITOR";
export const GET_DATEV_STATUS = "GET_DATEV_STATUS";
export const UPLOAD_PURCHASE_ORDER_INVOICES_TO_DATEV =
  "UPLOAD_PURCHASE_ORDER_INVOICES_TO_DATEV";
export const DOWNLOAD_SEPA = "DOWNLOAD_SEPA";
export const FETCH_COMPANY_ADDRESS = "FETCH_COMPANY_ADDRESS";

export const GET_LEGAL_TENDENCIES = "GET_LEGAL_TENDENCIES";
export const SAVE_LEGAL_TENDENCY = "SAVE_LEGAL_TENDENCY";
export const DELETE_LEGAL_TENDENCY = "DELETE_LEGAL_TENDENCY";

export const OPEN_CHARGE_BEE_PORTAL_SESSION = "OPEN_CHARGE_BEE_PORTAL_SESSION";

export const GET_INTEGRATIONS = "GET_INTEGRATIONS";
export const GET_INTEGRATIONS_SLUGS = "GET_INTEGRATIONS_SLUGS";
export const UPDATE_INTEGRATION = "UPDATE_INTEGRATION";

export const GET_INTEGRATION_URL = "GET_INTEGRATION_URL";

export const GET_CUSTOM_FIELDS = "GET_CUSTOM_FIELDS";

export const GET_TAX_CODES = "GET_TAX_CODES";

//Mutations
export const SET_DEPARTMENTS = "SET_DEPARTMENTS";
export const SET_DEPARTMENT = "SET_DEPARTMENT";
export const REMOVE_DEPARTMENT = "REMOVE_DEPARTMENT";
export const SET_PAYMENT_METHOD = "SET_PAYMENT_METHOD";
export const SET_PAYMENT_METHODS = "SET_PAYMENT_METHODS";

export const SET_COMPANY_ADDRESS = "SET_COMPANY_ADDRESS";
export const SET_COMPANY = "SET_COMPANY";

export const SET_COMPANY_ADDRESSES = "SET_COMPANY_ADDRESSES";

export const SET_CATEGORIES = "SET_CATEGORIES";
export const SET_CATEGORY = "SET_CATEGORY";
export const SET_USERS = "SET_USERS";
export const SET_USER = "SET_USER";
export const SET_PENDING_USERS = "SET_PENDING_USERS";

export const SET_PENDING_USER = "SET_PENDING_USER";
export const SET_USER_STATUS = "SET_USER_STATUS";

export const SET_COMPANY_INTEGRATED_SUPPLIERS =
  "SET_COMPANY_INTEGRATED_SUPPLIERS";
export const SET_INTEGRATIONS_SLUGS = "SET_INTEGRATIONS_SLUGS";
export const SET_COMPANY_SUPPLIERS = "SET_COMPANY_SUPPLIERS";
export const SET_COMPANY_SUPPLIER = "SET_COMPANY_SUPPLIER";
export const SET_SUPPLIER_CREDENTIALS = "SET_SUPPLIER_CREDENTIALS";

export const REMOVE_SUPPLIER = "REMOVE_SUPPLIER";

export const SET_COMPANY_SUPPLIERS_LIST_FILTERS =
  "SET_COMPANY_SUPPLIERS_LIST_FILTERS";

export const SET_WORKFLOW = "SET_WORKFLOW";

export const SET_BUDGETS = "SET_BUDGETS";
export const SET_DEPARTMENT_BUDGETS = "SET_DEPARTMENT_BUDGETS";
export const SET_USER_SUBSTITUTE = "SET_USER_SUBSTITUTE";

export const SET_GLOBAL_POLICIES = "SET_GLOBAL_POLICIES";
export const SET_GLOBAL_POLICY = "SET_GLOBAL_POLICY";

export const NULLIFY_MEMBER_EXPIRED_AVATAR = "NULLIFY_MEMBER_EXPIRED_AVATAR";
export const LIVE_UPDATE_DATA = "LIVE_UPDATE_DATA";

export const SET_QUICK_LINKS = "SET_QUICK_LINKS";

export const SET_DATEV_INTEGRATION = "SET_DATEV_INTEGRATION";

export const SET_COST_SAVING = "SET_COST_SAVING";
export const SET_COST_SAVING_GOALS = "SET_COST_SAVING_GOALS";

export const SET_LEGAL_TENDENCIES = "SET_LEGAL_TENDENCIES";
export const SET_LEGAL_TENDENCY = "SET_LEGAL_TENDENCY";
export const REMOVE_LEGAL_TENDENCY = "REMOVE_LEGAL_TENDENCY";

export const GET_COST_SAVINGS = "GET_COST_SAVINGS";
export const SET_COST_SAVINGS = "SET_COST_SAVINGS";

export const SET_INTEGRATIONS = "SET_INTEGRATIONS";
export const SET_INTEGRATION = "SET_INTEGRATION";
export const SET_API_KEYS = "SET_API_KEYS";
export const DISABLE_API_KEY = "DISABLE_API_KEY";
export const GET_API_KEYS = "GET_API_KEYS";
export const CREATE_API_KEY = "CREATE_API_KEY";

export const SET_CUSTOM_FIELDS = "SET_CUSTOM_FIELDS";

export const CREATE_DELIVERY_NOTE_REPORT = "CREATE_DELIVERY_NOTE_REPORT";
export const GET_ACTIVITY_HISTORY = "GET_ACTIVITY_HISTORY";

export const CONNECT_SINLE_DATEV_CLIENT = "CONNECT_SINLE_DATEV_CLIENT";
export const DISCONNECT_DATEV_SINGLE_CLIENT = "DISCONNECT_DATEV_SINGLE_CLIENT";
export const SET_EXCHANGE_RATE = "SET_EXCHANGE_RATE";

export const SET_TAX_CODES = "SET_TAX_CODES";
