<template>
  <div class="w-full">
    <HTable
      v-model="selectedOrders"
      :columns="columnsDetails"
      :data-function="fetchOrders"
      :show-actions="true"
      :on-confirmation-action="onConfirmationAction"
      :model-data="tableModelData"
      :show-filter-chips="filtersPresent"
    >
      <template #tableActions>
        <span v-if="filtersPresent">
          <Button :on-click-handler="clearFilters" :loading="loading">
            {{ $t("purchaseRequest.filtersOff") }}
          </Button>
        </span>
      </template>
      <template #title="slotProps">
        <div class="max-w-[280px]">
          <OrderTitle
            :set-quick-view="setQuickView"
            :set-detail-view="setDetailView"
            :purchase-order="slotProps.data"
          />
        </div>
      </template>

      <template #requestedFor="slotProps">
        <div class="text-sm">
          <UserWithAvatar
            :user="slotProps.data.purchaseRequest.requestedBy"
            show-name
            :avatar-dimension="7"
          />
        </div>
      </template>
      <template #confirmationAction="slotProps">
        <BarButton
          v-if="isAssigned(slotProps?.data?.id)"
          show-icon
          class="text-gray-600 group hover:bg-gray-500 hover:text-white"
          :on-click-handler="() => {}"
        >
          <template #icon>
            <PencilAltIcon class="text-hivebuy-red" />
          </template>
          {{ $t("invoice.editValues") }}
        </BarButton>

        <BarButton
          v-else
          show-icon
          class="text-gray-600 group hover:bg-hivebuy-green hover:text-white transition-all"
          :on-click-handler="() => {}"
        >
          <template #icon>
            <ClipboardCheckIcon
              class="text-hivebuy-green group-hover:text-white"
            />
          </template>
          {{ $t("invoice.assignOrder") }}
        </BarButton>
      </template>
    </HTable>
    <PurchaseRequestQuickView
      :show-modal="showQuickView"
      :close-modal="() => (showQuickView = false)"
      :purchase-request="quickViewOrder?.purchaseRequest"
      :navigate-to-detail-page="setDetailView"
      :is-purchase-order="true"
      :purchase-order="quickViewOrder"
      :type="'order'"
    />
  </div>
</template>
<script>
import { OrderTitle } from "@/components/PurchaseOrder/PurchaseOrdersList/TableComponents/index.js";
import POListingMixin from "@/components/mixins/POListingMixin.js";
import { ClipboardCheckIcon, PencilAltIcon } from "@heroicons/vue/solid";
import BarButton from "@/components/shared/BarButton.vue";
import { mapState } from "vuex";
import { PURCHASE_ORDER_MODULE } from "@/store/PurchaseOrder/types";
import { Button } from "@/components/shared";
export default {
  name: "OrdersList",
  components: {
    OrderTitle,
    ClipboardCheckIcon,
    PencilAltIcon,
    BarButton,
    Button,
  },
  mixins: [POListingMixin],
  props: {
    assignedOrders: {
      type: Array,
      default: () => [],
    },
    addPurchaseOrderToList: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      selectedOrders: [],
    };
  },

  computed: {
    ...mapState(PURCHASE_ORDER_MODULE, {
      purchaseOrders: (state) => state.purchaseOrders,
      purchaseOrdersListParams: (state) => state.purchaseOrdersListParams,
    }),
    filtersPresent() {
      let isValuePresent = false;
      const filters = this.purchaseOrdersListParams.filters || {};
      Object.keys(filters).forEach((key) => {
        if (filters[key].value) isValuePresent = true;
      });
      return isValuePresent;
    },
  },
  mounted() {
    if (this.filtersPresent) {
      this.clearFilters();
      this.fetchOrders(this.purchaseOrdersListParams);
    }
  },
  methods: {
    isAssigned(orderId) {
      return this.assignedOrders.includes(orderId);
    },
    clearFilters() {
      this.setPurchaseOrderListParams({ filters: {} });
    },

    onConfirmationAction(data) {
      const payload = {
        ...data,
      };
      this.addPurchaseOrderToList(payload, true);
    },
  },
};
</script>
<style>
.p-datatable {
  @apply border border-gray-300 rounded;
}

.p-datatable .p-datatable-tbody > tr {
  @apply hover:bg-gray-100;
}

.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even) {
  @apply hover:bg-gray-100;
}
.p-column-filter-menu-button {
  border-radius: 0px !important;
}
.custom-loading {
  @apply flex items-center py-8 px-20;
  @apply bg-gray-200/90 rounded border border-gray-400;
  @apply gap-x-4 text-primary;
  line-height: 0;
}

.p-toolbar-group-start {
  @apply w-1/2;
}

.p-toolbar {
  @apply !border-none;
}
</style>
